exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-actionable-language-md": () => import("./../../../../../@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/home/uxwriting/public_html/csg/Content-Style-Guide-in-Arabic/examples/gatsby-theme-docs/src/docs/actionable-language.md" /* webpackChunkName: "component---rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-actionable-language-md" */),
  "component---rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-overview-md": () => import("./../../../../../@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/home/uxwriting/public_html/csg/Content-Style-Guide-in-Arabic/examples/gatsby-theme-docs/src/docs/overview.md" /* webpackChunkName: "component---rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-overview-md" */),
  "component---rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-references-md": () => import("./../../../../../@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/home/uxwriting/public_html/csg/Content-Style-Guide-in-Arabic/examples/gatsby-theme-docs/src/docs/references.md" /* webpackChunkName: "component---rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-references-md" */),
  "component---rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-style-and-mechanics-md": () => import("./../../../../../@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/home/uxwriting/public_html/csg/Content-Style-Guide-in-Arabic/examples/gatsby-theme-docs/src/docs/style-and-mechanics.md" /* webpackChunkName: "component---rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-style-and-mechanics-md" */),
  "component---rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-voice-and-tone-md": () => import("./../../../../../@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/home/uxwriting/public_html/csg/Content-Style-Guide-in-Arabic/examples/gatsby-theme-docs/src/docs/voice-and-tone.md" /* webpackChunkName: "component---rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-voice-and-tone-md" */),
  "component---rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-word-list-md": () => import("./../../../../../@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/home/uxwriting/public_html/csg/Content-Style-Guide-in-Arabic/examples/gatsby-theme-docs/src/docs/word-list.md" /* webpackChunkName: "component---rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-word-list-md" */),
  "component---rocketseat-gatsby-theme-docs-core-src-templates-homepage-query-js-content-file-path-src-home-index-mdx": () => import("./../../../../../@rocketseat/gatsby-theme-docs-core/src/templates/homepage-query.js?__contentFilePath=/home/uxwriting/public_html/csg/Content-Style-Guide-in-Arabic/examples/gatsby-theme-docs/src/home/index.mdx" /* webpackChunkName: "component---rocketseat-gatsby-theme-docs-core-src-templates-homepage-query-js-content-file-path-src-home-index-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

